<template lang='pug'>
.settings-email.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    //- fi-form-field(
    //-   :label     = '$t("server")'
    //-   label-cols = '2'
    //- )
    //-   input.form-control.form-control-sm(v-model.trim = 'settingsData.mailServer')

    //- fi-form-field(
    //-   :label     = '$t("port")'
    //-   label-cols = '2'
    //- )
    //-   input.form-control.form-control-sm(v-model = 'settingsData.mailServerPort')

    fi-form-field(
      :label     = '$t("sender")'
      label-cols = '2'
    )
      input.form-control.form-control-sm(v-model.trim = 'settingsData.mailSender' autocomplete = 'new-sender')

    //- fi-form-field(
    //-   :label     = '$t("username")'
    //-   label-cols = '2'
    //- )
    //-   input.form-control.form-control-sm(
    //-     v-model.trim = 'settingsData.mailUsername'
    //-     autocomplete = 'new-username'
    //-   )

    //- fi-form-field(
    //-   :label     = '$t("password")'
    //-   label-cols = '2'
    //- )
    //-   input.form-control.form-control-sm(
    //-     v-model = 'settingsData.mailPassword'
    //-     type    = 'password'
    //-     autocomplete = 'new-password'
    //-   )
    fi-form-field(
      :label     = '$t("defaultSenderId")'
      label-cols = '2'
    )
      fi-select(
        v-model.number  = 'settingsData.defaultMailSenderUser'
        :options        = 'optionsFromCreditIssuers'
        sm
      )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'settings-email',

  components: {
    FiFormField,
    FiSelect
  },

  props: {
    settingsData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: mapGetters('classifiers', ['optionsFromCreditIssuers', 'optionsFromManagers'])
}
</script>


<i18n>
en:
  title:                "E-mail settings"
  server:               "Server"
  port:                 "Port"
  sender:               "Sender email"
  username:             "Username"
  password:             "Password"
  defaultSenderId:      "Automatic notification sender user"
et:
  title:                "E-mail settings"
  server:               "Server"
  port:                 "Port"
  sender:               "Sender email"
  username:             "Username"
  password:             "Password"
  defaultSenderId:      "Automatic notification sender user"
ru:
  title:                "E-mail settings"
  server:               "Server"
  port:                 "Port"
  sender:               "Sender email"
  username:             "Username"
  password:             "Password"
  defaultSenderId:      "Automatic notification sender user"
</i18n>
