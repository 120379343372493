<template lang='pug'>
 .settings-customer-limit.card.animated.fadeIn
   .card-header {{ $t('title') }}
   .card-body
     fi-form-field(
       :label     = '$t("creditLimitInitial")'
       label-cols = 2
     )
      input.form-control-sm.form-control(v-model = 'settingsData.creditLimitInitial')
     fi-form-field(
       :label     = '$t("creditLimitMax")'
       label-cols = 2
     )
       input.form-control-sm.form-control(v-model = 'settingsData.creditLimitMax')
     fi-form-field(
       :label     = '$t("limitIncreaseStep")'
       label-cols = 2
     )
       input.form-control-sm.form-control(v-model = 'settingsData.creditLimitIncreaseStep')
</template>


<script>
import FiFormField from '@/components/FiFormField'

export default {
  name: 'settings-customer-limit',
  components: { FiFormField },
  props: {
    settingsData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  title:              "Customer credit limit"
  creditLimitInitial: "Initial credit limit"
  creditLimitMax:     "Maximum credit limit"
  limitIncreaseStep:  "Limit increase step"
et:
  title:              "Customer credit limit"
  creditLimitInitial: "Initial credit limit"
  creditLimitMax:     "Maximum credit limit"
  limitIncreaseStep:  "Limit increase step"
ru:
  title:              "Customer credit limit"
  creditLimitInitial: "Initial credit limit"
  creditLimitMax:     "Maximum credit limit"
  limitIncreaseStep:  "Limit increase step"
</i18n>
