<template lang='pug'>
.settings-credit-issuer.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    fi-form-field(
      :label     = '$t("name")'
      label-cols = 2
    )
      input.form-control-sm.form-control(v-model = 'settingsData.creditIssuerName')
    fi-form-field(
      :label     = '$t("address")'
      label-cols = 2
    )
      input.form-control-sm.form-control(v-model = 'settingsData.creditIssuerAddress')
    fi-form-field(
      :label     = '$t("logo")'
      label-cols = 2
    )
      fi-file-input(
        @input    = 'uploadLogo'
        :disabled = 'uploadingLogo'
      )
      v-loading.text-center(:when = 'downloadingLogo')
        template(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
        b-img(
          if   = 'logo'
          :src = 'logo'
          thumbnail
          fluid
        )
    fi-form-field(
      :label     = '$t("email")'
      label-cols = 2
    )
      input.form-control-sm.form-control(v-model = 'settingsData.creditIssuerEmail')
    fi-form-field(
      :label     = '$t("skype")'
      label-cols = 2
    )
      input.form-control-sm.form-control(v-model = 'settingsData.creditIssuerSkype')
    fi-form-field(
      :label     = '$t("phone")'
      label-cols = 2
    )
      fi-phone-input.form-control-sm.form-control(
        v-model.trim = 'settingsData.creditIssuerPhone'
        :countries   = 'countries'
      )
    fi-form-field(
      :label     = '$t("homepage")'
      label-cols = 2
    )
      input.form-control-sm.form-control(v-model = 'settingsData.creditIssuerHomepage')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiFileInput from '@/components/FiFileInput'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'settings-credit-issuer',

  components: {
    FiMultiselect,
    FiPhoneInput,
    FiFormField,
    FiFileInput
  },

  props: {
    settingsData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('settings', ['logo']),
    ...mapGetters('classifiers', ['classifierList']),
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    uploadingLogo () {
      return this.$vueLoading.isLoading('setting:creditIssuerLogo:file:save')
    },
    downloadingLogo () {
      return this.$vueLoading.isLoading('system:logo:fetch')
    },
    creditIssuerBankAccountModel: {
      get () {
        return this.settingsData.creditIssuerBankAccount.split(',').map(item => ({ value: item.trim(), text: item.trim() }))
      },
      set (value) {
        this.settingsData.creditIssuerBankAccount = value.map(({ text }) => text).join()
      }
    }
  },

  watch: {
    'settingsData.creditIssuerLogo' (val) {
      if (val) this.loadLogo()
    }
  },

  methods: {
    ...mapActions('settings', ['loadLogo', 'saveSettingFile']),
    async uploadLogo (file) {
      if (file) {
        this.settingsData.creditIssuerLogo = await this.saveSettingFile({ key: 'creditIssuerLogo', file })
      }
    },
    addAccount (account) {
      this.creditIssuerBankAccountModel = [...this.creditIssuerBankAccountModel, { text: account, value: account }]
    }
  }
}
</script>


<i18n>
en:
  title:       "Credit issuer info (portal)"
  name:        "Brand name"
  address:     "Address"
  logo:        "Logo"
  email:       "E-mail"
  skype:       "Skype"
  phone:       "Phone"
  homepage:    "Homepage"
et:
  title:       "Credit issuer info (portal)"
  name:        "Brand name"
  address:     "Address"
  logo:        "Logo"
  email:       "E-mail"
  skype:       "Skype"
  phone:       "Phone"
  homepage:    "Homepage"
ru:
  title:       "Credit issuer info (portal)"
  name:        "Brand name"
  address:     "Address"
  logo:        "Logo"
  email:       "E-mail"
  skype:       "Skype"
  phone:       "Phone"
  homepage:    "Homepage"
</i18n>
