<template lang='pug'>
  .settings-payments.card.animated.fadeIn
    .card-header {{ $t('title') }}
    .card-body
      fi-form-field(
        :label     = '$t("portalPaymentsEnabled")'
        label-cols = '3'
      )
        .switch-status-wrap
          fi-switch(
            v-model   = 'settingsData.portalPaymentsEnabled'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
      fi-form-field(
        :label     = '$t("portalCreditIssuer")'
        label-cols = 3
      )
        fi-select(
          v-model   = 'settingsData.portalCreditIssuer'
          :options  = 'issuersNames'
          sm
        )
      fi-form-field(
        :label     = '$t("payoutCreditIssuerIban")'
        label-cols = 3
      )
        fi-multiselect(
          v-model  = 'settingsData.portalCreditIssuerIban'
          :options = 'IbanValues'
          track-by = 'value'
          label    = 'text'
          multiple
        )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSwitch from '@/components/FiSwitch'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'settings-payouts',

  components: {
    FiSwitch,
    FiMoneyInput,
    FiFormField,
    FiSelect,
    FiMultiselect
  },

  props: {
    settingsData: {
      type: Object,
      required: true
    },
    creditIssuers: {
      type: Array,
      required: true
    }
  },
  computed: {
    issuersNames () {
      return this.creditIssuers ? this.creditIssuers.map(item => {
        return {
          value: item.customerName,
          text: item.customerName
        }
      }) : []
    },
    IbanValues () {
      const filteredIssuers = this.creditIssuers.filter(item => {
        return item.customerName === this.settingsData.portalCreditIssuer
      })
      return (filteredIssuers.length > 0 && filteredIssuers[0].externalBankAccounts) ? filteredIssuers[0].externalBankAccounts?.map(item => {
        return {
          value: item.accountExternalNumber,
          text: item.accountExternalNumber
        }
      }) : []
    }
  },
  watch: {
    'settingsData.portalCreditIssuer' (newVal, prevVal) {
      if (prevVal && newVal) {
        this.settingsData.portalCreditIssuerIban = ''
      }
    }
  }
}
</script>


<i18n>
en:
  title:                  "Payout from portal"
  payoutCreditIssuer:     "Credit issuer"
  payoutCreditIssuerIban: "Payout IBAN accounts from portal"
  portalPaymentsEnabled:  "Portal payments enabled"
  portalCreditIssuer:     "Portal credit issuer"
et:
  title:                  "Payout from portal"
  payoutCreditIssuer:     ""
  payoutCreditIssuerIban: ""
  portalCreditIssuer:     "Portal credit issuer"
ru:
  title:                  "Payout from portal"
  payoutCreditIssuer:     ""
  payoutCreditIssuerIban: ""
  portalCreditIssuer:     "Portal credit issuer"
</i18n>
