<template lang='pug'>
.settings-messente.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    fi-form-field(
      :label     = '$t("common:enabled")'
      label-cols = '2'
    )
      fi-switch(v-model = 'settingsData.messenteEnabled')
    fi-form-field(
      :label     = '$t("username")'
      label-cols = 2
    )
      input.form-control.form-control-sm(v-model = 'settingsData.messenteUsername' autocomplete = 'new-username')
    fi-form-field(
      :label     = '$t("password")'
      label-cols = 2
    )
      input.form-control.form-control-sm(
        v-model = 'settingsData.messentePassword'
        type    = 'password'
        autocomplete = 'new-password'
      )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'settings-messente',

  components: { FiSwitch, FiFormField },

  props: {
    settingsData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  title:    "Messente settings"
  username: "Username"
  password: "Password"
et:
  title:    "Messente settings"
  username: "Username"
  password: "Password"
ru:
  title:    "Messente settings"
  username: "Username"
  password: "Password"
</i18n>
