<template lang="pug">
.settings-credit-issuer.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    .row
      .col
        ul.list-group.font-weight-bold
          li.list-group-item.border-0.list {{ $t("name") }}
          li.list-group-item.border-0 {{ $t("regNumber") }}
          li.list-group-item.border-0 {{ $t("bankAccount") }}
          li.list-group-item.border-0 {{ $t("email") }}
          li.list-group-item.border-0 {{ $t("phone") }}
          li.list-group-item.border-0 {{ $t("address") }}
      .col(v-for = 'creditIssuer in creditIssuers')
        ul.list-group
          li.list-group-item.border-0
            router-link(:to = '{ name: "Customer", params: { id: creditIssuer.id } }') {{ getIssuerName(creditIssuer) }}
          li.list-group-item.border-0 {{ creditIssuer.idCode }}
          li.list-group-item.border-0
            b-dd(
              v-if    = 'getExternalBankAccounts(creditIssuer) > 1'
              :text   = 'getIssuerBankAccount(creditIssuer)'
              variant = 'white'
              toggle-class = 'px-0'
            )
              b-dd-item(
                v-for = 'account in creditIssuer.externalBankAccounts'
                v-if  = '!account.generalAccount'
                :key  = 'account.accountExternalNumber'
              ) {{ account.accountExternalNumber }}
            template(v-else) {{ getIssuerBankAccount(creditIssuer) || 'N/A' }}
          li.list-group-item.border-0 {{ getIssuerEmail(creditIssuer) }}
          li.list-group-item.border-0 {{ getIssuerPhone(creditIssuer) }}
          li.list-group-item.border-0 {{ getIssuerAddress(creditIssuer) }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { customerName, findCustomerMainContact } from '@/helpers'

export default {
  name: 'settings-credit-issuers-info',

  components: { FiDataField },

  props: {
    creditIssuers: {
      type: Array,
      required: true
    }
  },

  methods: {
    getIssuerName: customerName,
    getIssuerBankAccount (creditIssuer) {
      return creditIssuer.defaultExternalAccount?.accountExternalNumber
    },
    getIssuerEmail (creditIssuer) {
      return findCustomerMainContact(creditIssuer.emails)
    },
    getIssuerPhone (creditIssuer) {
      return findCustomerMainContact(creditIssuer.phoneNumbers)
    },
    getIssuerAddress (creditIssuer) {
      return creditIssuer.defaultAddress?.fullAddress
    },
    getExternalBankAccounts (creditIssuer) {
      return creditIssuer.externalBankAccounts?.length
    }
  }
}
</script>


<i18n>
en:
  title:       "Credit issuer data"
  name:        "Name"
  regNumber:   "Registration number"
  bankAccount: "Bank account (Nostro)"
  email:       "Email"
  phone:       "Phone"
  address:     "Address"
et:
  title:       "Credit issuer data"
  name:        "Name"
  regNumber:   "Registration number"
  bankAccount: "Bank account (Nostro)"
  email:       "Email"
  phone:       "Phone"
  address:     "Address"
ru:
  title:       "Credit issuer data"
  name:        "Name"
  regNumber:   "Registration number"
  bankAccount: "Bank account (Nostro)"
  email:       "Email"
  phone:       "Phone"
  address:     "Address"
</i18n>
