<template lang="pug">
.settings(v-if = 'isVisible')
  v-loading(loader = 'settings:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    form.form-horizontal(@submit.prevent = 'onSubmit' autocomplete='off')

      template(v-if = 'isEditable')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          type      = 'button'
          :disabled = 'saving'
          @click    = 'resetData'
        ) {{ $t('common:cancel') }}

      settings-credit-issuer.mt-3(:settings-data = 'creditIssuerData')
      settings-credit-issuers-info(:credit-issuers = 'creditIssuers')
      settings-payments(
        v-if            = 'outgoingPaymentEnabled'
        :settings-data  = 'paymentsData'
        :credit-issuers = 'creditIssuers'
      )
      settings-payout(
        v-if            = 'outgoingPaymentEnabled'
        :settings-data  = 'paymentsData'
        :credit-issuers = 'creditIssuers'
      )
      settings-system(:settings-data = 'settingsData')
      settings-email(
        v-if           = 'showMailSettings'
        :settings-data = 'mailServerData'
      )
      settings-messente(
        v-if           = 'showMessenteSettings'
        :settings-data = 'messenteData'
      )
      settings-customer-limit(
        v-if           = 'isLimitEnabled'
        :settings-data = 'creditLimitData'
      )

      template(v-if = 'isEditable')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          type      = 'button'
          :disabled = 'saving'
          @click    = 'resetData'
        ) {{ $t('common:cancel') }}
</template>


<script>
import { permissions } from '@/mixins'
import SettingsCustomerLimit from '@/views/admin/settings/SettingsCustomerLimit'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import SettingsCreditIssuer from '@/views/admin/settings/SettingsCreditIssuer'
import SettingsSystem from '@/views/admin/settings/SettingsSystem'
import SettingsEmail from '@/views/admin/settings/SettingsEmail'
import SettingsMessente from '@/views/admin/settings/SettingsMessente'
import SettingsPayments from '@/views/admin/settings/SettingsPayments'
import SettingsPayout from '@/views/admin/settings/SettingsPayout'
import { parseStringBoolean } from '@/helpers'
import SettingsCreditIssuersInfo from '@/views/admin/settings/SettingsCreditIssuersInfo'
import api from '@/api'

export default {
  name: 'settings',

  components: {
    SettingsCreditIssuersInfo,
    SettingsPayments,
    SettingsCustomerLimit,
    SettingsMessente,
    SettingsEmail,
    SettingsSystem,
    SettingsPayout,
    SettingsCreditIssuer
  },

  mixins: [permissions],

  i18nOptions: {},

  data () {
    return {
      creditIssuers: [],
      settingsData: {
        apr: '',
        p2s: '',
        currency: '',
        currencySymbol: '',
        language: '',
        country: '',
        noPenaltyDates: '',
        depositMode: false,
        factoringMode: false,
        scoringMode: false,
        customerPeriodBeforeDeletion: '',
        customerApplicationAcceptanceDisabledPeriod: '',
        supportedBoLanguages: '',
        supportedPortalLanguages: '',
        supportedTemplateLanguages: ''
      },
      creditIssuerData: {
        creditIssuerName: '',
        creditIssuerAddress: '',
        creditIssuerEmail: '',
        creditIssuerSkype: '',
        creditIssuerHomepage: '',
        creditIssuerPhone: '',
        creditIssuerLogo: ''
      },
      mailServerData: {
        // mailServer: '',
        // mailServerPort: '',
        // mailUsername: '',
        // mailPassword: '',
        mailSender: '',
        defaultMailSenderUser: ''
      },
      messenteData: {
        messenteEnabled: false,
        messenteUsername: '',
        messentePassword: ''
      },
      paymentsData: {
        portalPaymentsEnabled: false,
        customerManualConfirmThreshold: '',
        portalCreditIssuer: '',
        portalCreditIssuerIban: ''
      },
      creditLimitData: {
        creditLimitInitial: '',
        creditLimitMax: '',
        creditLimitIncreaseStep: ''
      }
    }
  },

  computed: {
    ...mapState('settings', ['settings']),
    ...mapGetters('settings', ['isLimitEnabled']),
    ...mapGetters('classifiers', ['optionsFromCreditIssuers']),
    saving () {
      return this.$vueLoading.isLoading('settings:save')
    },
    showMailSettings () {
      return !!Object.keys(this.mailServerData).length
    },
    showMessenteSettings () {
      return !!Object.keys(this.messenteData).length
    },
    outgoingPaymentEnabled () {
      return Number.parseInt(this.settings?.outgoingPaymentEnabled) || true
    }
  },

  async created () {
    if (this.isVisible) {
      await this.loadCreditIssuers()
      await this.loadSettings({ force: true })

      this.resetData()
    }
  },

  methods: {
    ...mapActions('settings', ['loadSettings', 'saveSettings']),
    ...mapActions('classifiers', ['loadCreditIssuers']),
    async resetData () {
      const creditIssuers = this.optionsFromCreditIssuers.map(creditIssuer => api.getCustomer({ id: creditIssuer.value }).then(({ data }) => data))
      this.creditIssuers = await Promise.all(creditIssuers)
      this.settingsData = pick(this.settings, Object.keys(this.settingsData))
      this.settingsData.depositMode = parseStringBoolean(this.settings.depositMode)
      this.settingsData.factoringMode = parseStringBoolean(this.settings.factoringMode)
      this.settingsData.scoringMode = parseStringBoolean(this.settings.scoringMode)
      this.creditIssuerData = pick(this.settings, Object.keys(this.creditIssuerData))
      this.mailServerData = pick(this.settings, Object.keys(this.mailServerData))
      this.messenteData = pick(this.settings, Object.keys(this.messenteData))
      this.messenteData.messenteEnabled = parseStringBoolean(this.settings.messenteEnabled)
      this.paymentsData = pick(this.settings, Object.keys(this.paymentsData))
      this.paymentsData.portalPaymentsEnabled = this.paymentsData.portalPaymentsEnabled === 'true'
      this.paymentsData.portalCreditIssuerIban = this.paymentsData.portalCreditIssuerIban === '' ? [] : this.paymentsData.portalCreditIssuerIban.split(',').map(item => {
        return {
          value: item,
          text: item
        }
      })
      this.creditLimitData = pick(this.settings, Object.keys(this.creditLimitData))
    },
    async onSubmit () {
      const paymentsData = Object.assign({}, this.paymentsData)
      paymentsData.portalCreditIssuerIban = paymentsData.portalCreditIssuerIban.map(item => item.value).join(',')
      await this.saveSettings({
        data: {
          ...this.settingsData,
          ...this.creditIssuerData,
          ...this.mailServerData,
          ...this.messenteData,
          ...paymentsData,
          ...(this.isLimitEnabled ? this.creditLimitData : {})
        }
      })
      this.resetData()
      this.$toasted.success(this.$t('success'))
    }
  }
}
</script>


<i18n>
en:
  success: "Settings changes successfully saved"
et:
  success: "Settings changes successfully saved"
ru:
  success: "Settings changes successfully saved"
</i18n>
