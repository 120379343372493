<template lang='pug'>
  .settings-payments.card.animated.fadeIn
    .card-header {{ $t('title') }}
    .card-body
      fi-form-field(
        :label     = '$t("customerManualConfirmThreshold")'
        label-cols = 3
      )
        fi-money-input.form-control.form-control-sm(v-model = 'settingsData.customerManualConfirmThreshold')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'settings-payments',

  components: {
    FiSwitch,
    FiMoneyInput,
    FiFormField
  },

  props: {
    settingsData: {
      type: Object,
      required: true
    }
  }
}
</script>


<i18n>
en:
  title:                          "Payments"
  customerManualConfirmThreshold: "Customer manual payment confirm threshold"
et:
  title:                          "Payments"
  customerManualConfirmThreshold: "Customer manual payment confirm threshold"
ru:
  title:                          "Payments"
  customerManualConfirmThreshold: "Customer manual payment confirm threshold"
</i18n>
