<template lang='pug'>
.settings-system.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    fi-form-field(
      :label     = '$t("apr")'
      label-cols = '2'
    )
      percent-input.form-control.form-control-sm(
        type    = 'text'
        v-model = 'settingsData.apr'
      )
    fi-form-field(
      :label     = '$t("p2s")'
      label-cols = '2'
    )
      input-tag(
        :placeholder = '$t("p2sPlaceholder")'
        :tags        = 'p2sSplitted'
        @change      = 'parseP2s'
      )
    fi-form-field(
      :label     = '$t("currency")'
      label-cols = '2'
    )
      .input-group
        input.form-control.form-control-sm(
          type    = 'text'
          v-model = 'settingsData.currency'
        )
        .input-group-append
          input.form-control.form-control-sm(
            type    = 'text'
            v-model = 'settingsData.currencySymbol'
          )
    fi-form-field(
      :label     = '$t("supportedBoLanguages")'
      label-cols = '2'
    )
      fi-multiselect(
        v-model = 'supportedBoLanguages'
        :options = 'optionsFromClassifier("languages", true)'
        multiple
        hide-selected
      )

    fi-form-field(
      :label     = '$t("supportedPortalLanguages")'
      label-cols = '2'
    )
      fi-multiselect(
        v-model = 'supportedPortalLanguages'
        :options = 'optionsFromClassifier("languages", true)'
        multiple
        hide-selected
      )

    fi-form-field(
      :label     = '$t("supportedTemplateLanguages")'
      label-cols = '2'
    )
      fi-multiselect(
        v-model = 'supportedTemplateLanguages'
        :options = 'optionsFromClassifier("languages", true)'
        multiple
        hide-selected
      )

    fi-form-field(
      :label     = '$t("language")'
      label-cols = '2'
    )
      fi-select(
        v-model  = 'settingsData.language'
        :options = 'supportedBoLanguages'
        sm
        required
      )
    fi-form-field(
      :label     = '$t("country")'
      label-cols = '2'
    )
      fi-select(
        v-model  = 'settingsData.country'
        :options = 'optionsFromClassifier("countries", true)'
        sm
        required
      )
    fi-form-field(
      :label     = '$t("noPenaltyDates")'
      label-cols = '2'
    )
      fi-datepicker(
        v-model         = 'noPenaltyDates'
        mode            = 'multiple'
        :allow-input    = 'false'
        :disabled-dates = 'preselectedDates'
        inline
      )
    fi-form-field(
      :label     = '$t("depositMode")'
      label-cols = '2'
    )
      fi-switch(v-model = 'settingsData.depositMode')

    fi-form-field(
      :label     = '$t("factoringMode")'
      label-cols = '2'
    )
      fi-switch(v-model = 'settingsData.factoringMode')

    fi-form-field(
      :label     = '$t("scoringMode")'
      label-cols = '2'
    )
      fi-switch(v-model = 'settingsData.scoringMode')

    fi-form-field(
      :label     = '$t("customerDeletePeriod")'
      label-cols = '2'
    )
      input.form-control-sm.form-control(v-model = 'settingsData.customerPeriodBeforeDeletion')

    fi-form-field(
      :label     = '$t("applicationAcceptancePeriod")'
      label-cols = '2'
    )
      input.form-control-sm.form-control(v-model = 'settingsData.customerApplicationAcceptanceDisabledPeriod')
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import InputTag from 'vue-input-tag'
import FiFormField from '@/components/FiFormField'
import PercentInput from '@/components/PercentInput'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'settings-system',

  components: {
    FiMultiselect,
    FiSwitch,
    FiDatepicker,
    FiSelect,
    InputTag,
    FiFormField,
    PercentInput,
    draggable
  },

  props: {
    settingsData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    p2sSplitted () {
      if (!this.settingsData.p2s) {
        return
      }
      return this.settingsData.p2s.split('|')
    },
    preselectedDates () {
      return {
        weekdays: [1, 7]
      }
    },
    noPenaltyDates: {
      get () {
        if (!this.settingsData.noPenaltyDates) return this.settingsData.noPenaltyDates
        return this.settingsData.noPenaltyDates.split(',').map(item => item.trim())
      },
      set (list) {
        this.settingsData.noPenaltyDates = list.join(',')
      }
    },
    supportedBoLanguages: {
      get () {
        return this.settingsData.supportedBoLanguages.split(',').map(item => ({ value: item.trim(), text: item.trim() }))
      },
      set (list) {
        this.settingsData.supportedBoLanguages = list.map(({ value }) => value).join(',')
      }
    },
    supportedPortalLanguages: {
      get () {
        return this.settingsData.supportedPortalLanguages.split(',').map(item => ({ value: item.trim(), text: item.trim() }))
      },
      set (list) {
        this.settingsData.supportedPortalLanguages = list.map(({ value }) => value).join(',')
      }
    },
    supportedTemplateLanguages: {
      get () {
        return this.settingsData.supportedTemplateLanguages.split(',').map(item => ({ value: item.trim(), text: item.trim() }))
      },
      set (list) {
        this.settingsData.supportedTemplateLanguages = list.map(({ value }) => value).join(',')
      }
    }
  },

  methods: {
    parseP2s (value) {
      this.settingsData.p2s = value.join(' | ')
    }
  }
}
</script>


<i18n>
en:
  title:                       "System settings"
  apr:                         "Maximum Annual Percentage Rate"
  p2s:                         "Second Pillar of the Pension Exceptions"
  p2sPlaceholder:              "Insert value and press Enter"
  currency:                    "Currency"
  language:                    "Default language"
  country:                     "Country"
  noPenaltyDates:              "No penalty dates"
  depositMode:                 "Deposits enabled"
  factoringMode:               "Factoring enabled"
  scoringMode:                 "Scoring enabled"
  customerDeletePeriod:        "Customer delete period"
  applicationAcceptancePeriod: "Nr of days applying for a loan not allowed in case of negative scoring result"
  supportedBoLanguages:        "Supported BO languages"
  supportedPortalLanguages:    "Supported portal languages"
  supportedTemplateLanguages:  "Supported template languages"
et:
  title:                       "System settings"
  apr:                         "Maximum Annual Percentage Rate"
  p2s:                         "Second Pillar of the Pension Exceptions"
  p2sPlaceholder:              "Insert value and press Enter"
  currency:                    "Currency"
  language:                    "Default language"
  country:                     "Country"
  noPenaltyDates:              "No penalty dates"
  depositMode:                 "Deposits enabled"
  factoringMode:               "Factoring enabled"
  scoringMode:                 "Scoring enabled"
  customerDeletePeriod:        "Customer delete period"
  applicationAcceptancePeriod: "Nr of days applying for a loan not allowed in case of negative scoring result"
  supportedBoLanguages:        "Supported BO languages"
  supportedPortalLanguages:    "Supported portal languages"
  supportedTemplateLanguages:  "Supported template languages"
ru:
  title:                       "System settings"
  apr:                         "Maximum Annual Percentage Rate"
  p2s:                         "Second Pillar of the Pension Exceptions"
  p2sPlaceholder:              "Insert value and press Enter"
  currency:                    "Currency"
  language:                    "Default language"
  country:                     "Country"
  noPenaltyDates:              "No penalty dates"
  depositMode:                 "Deposits enabled"
  factoringMode:               "Factoring enabled"
  scoringMode:                 "Scoring enabled"
  customerDeletePeriod:        "Customer delete period"
  applicationAcceptancePeriod: "Nr of days applying for a loan not allowed in case of negative scoring result"
  supportedBoLanguages:        "Supported BO languages"
  supportedPortalLanguages:    "Supported portal languages"
  supportedTemplateLanguages:  "Supported template languages"
</i18n>
